<template>
  <ModalBgWrapper
    :open="open"
    class-name="z-[101]"
    body-class="rounded-t-3xl md:rounded-b-3xl"
    @close="onCloseDialog"
  >
    <div class="flex max-h-[85vh] min-h-[85vh] flex-col bg-[#2b2b2b] md:w-[600px]">
      <label class="flex cursor-text items-center gap-2 px-6 py-5 text-sm text-white/50">
        <input
          v-model="searchString"
          type="text"
          placeholder="Search Token or Address"
          class="flex-1 bg-transparent outline-none"
        />
        <Icon class="text-lg" icon="eva:search-outline" />
      </label>
      <div class="grid flex-1 grid-cols-[auto_1fr] overflow-auto bg-[#202020]">
        <div class="flex flex-col overflow-auto border-r border-t border-solid border-[#363636]">
          <CategoryItem
            logo="akar-icons:link-chain"
            name="All"
            :selected="isChainSelected('all')"
            @click="handleSelectChainOrCategory('all')"
          />
          <CategoryItem
            logo="lucide:sparkles"
            name="Native"
            :selected="isChainSelected('native')"
            @click="handleSelectChainOrCategory('native')"
          />
          <div id="asset-select" role="tree" aria-label="Select assets">
            <template v-if="sortedCuratedRoutes.state === 'loaded'">
              <NetworkItem
                v-for="item in sortedCuratedRoutes.data"
                :key="item.chain"
                :chain="item.chain"
                :selected="isChainSelected(item.chain)"
                @click="handleSelectChainOrCategory(item.chain)"
              />
            </template>
            <template v-else-if="curatedRoutes.state === 'loading'">
              <NetworkItem
                v-for="(item, index) in Array(20).fill('')"
                :key="index"
                :chain="item"
                skeleton
              />
            </template>
          </div>
        </div>
        <div
          v-if="displayedAssets.state === 'loaded'"
          :id="`asset-select-${selectedChainOrCategory.toLowerCase()}`"
          class="flex flex-col overflow-auto border-t border-solid border-[#363636]"
          :aria-label="`${selectedChainOrCategory} assets`"
          role="group"
        >
          <template v-if="displayedAssets.state === 'loaded' && displayedAssets.data.length > 0">
            <AssetItem
              v-for="asset in displayedAssets.data"
              :key="asset.assetId"
              :asset="asset"
              :selected="isAssetSelected(asset.assetId)"
              @click="(event) => onAssetSelect({ asset, event })"
            />
          </template>
          <div
            v-else-if="displayedAssets.state === 'loaded'"
            class="flex h-full flex-col justify-center"
          >
            <NoMatching />
          </div>
          <template v-else-if="displayedAssets.state === 'loading'">
            <AssetItem v-for="(item, index) in Array(20).fill('')" :key="index" skeleton />
          </template>
        </div>
      </div>
    </div>
  </ModalBgWrapper>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed, ref, toRefs } from 'vue'
import Fuse from 'fuse.js'
import AssetItem from './CompAssetSelector/AssetItem.vue'
import NetworkItem from './CompAssetSelector/NetworkItem.vue'
import CategoryItem from './CompAssetSelector/CategoryItem.vue'
import { sortCuratedRoutes } from '~/utils/route'
import type { AssetSelectorData } from '~/types/route'
import type { RangoChain } from '~/types/rango'
import type { WalletChain } from '~/wallets/swapkit'
import type { State } from '~/types/state'
import nativeAssets from '~/config/nativeAssets.json'
import { sortByBalanceAndName } from '~/utils/helpers'

const props = defineProps<{
  open: boolean
  curatedRoutes: State<AssetSelectorData[]>
  searchableRoutes: State<AssetSelectorData[]>
  poolToUpdate: PoolForDialog
  curSelectedAsset: RouteEndPoint
}>()
const { open, curatedRoutes, searchableRoutes, poolToUpdate, curSelectedAsset } = toRefs(props)

const emits = defineEmits<{
  close: []
  select: [{ poolToUpdate: PoolForDialog; newSelectedAsset: RouteEndPoint }]
}>()

const sortedCuratedRoutes = computed(() => {
  if (curatedRoutes.value.state === 'loaded')
    return {
      state: 'loaded',
      data: [...curatedRoutes.value.data].sort(sortCuratedRoutes),
    }
  else return curatedRoutes.value // return 'loading' or 'error'
})

const selectedChainOrCategory = ref<WalletChain | RangoChain | 'all' | 'native'>(
  curSelectedAsset.value.chain,
)

const isChainSelected = (chainOrCategory: string) =>
  chainOrCategory.toUpperCase() === selectedChainOrCategory.value.toUpperCase()
const isAssetSelected = (assetId: string) =>
  assetId.toUpperCase() === curSelectedAsset.value.assetId.toUpperCase()

const searchString = ref('')

const handleSelectChainOrCategory = (
  chainOrCategory: WalletChain | RangoChain | 'all' | 'native',
) => {
  selectedChainOrCategory.value = chainOrCategory
}

const allCuratedAssets = computed(() => {
  if (curatedRoutes.value.state === 'loaded')
    return {
      state: 'loaded',
      data: curatedRoutes.value.data.flatMap((assetSelectorData) => assetSelectorData.assets),
    }
  else return curatedRoutes.value // return 'loading' or 'error'
})

const allNativeAssets = computed(() => {
  if (curatedRoutes.value.state === 'loaded')
    return {
      state: 'loaded',
      data: curatedRoutes.value.data
        .flatMap((assetSelectorData) => assetSelectorData.assets)
        .filter((asset) => nativeAssets.includes(asset.assetId)),
    }
  else return curatedRoutes.value // return 'loading' or 'error'
})

const displayedAssets = computed(() => {
  const maybeUnsortedAssets =
    searchString.value === '' ? selectedCuratedRoutes.value : searchedAssets.value

  if (maybeUnsortedAssets.state === 'loaded') {
    return {
      state: 'loaded',
      data: maybeUnsortedAssets.data.sort(sortByBalanceAndName),
    }
  } else return maybeUnsortedAssets
})

const selectedCuratedRoutes = computed(() => {
  if (selectedChainOrCategory.value === 'all') return allCuratedAssets.value
  else if (selectedChainOrCategory.value === 'native') return allNativeAssets.value
  else if (curatedRoutes.value.state === 'loaded')
    return {
      state: 'loaded',
      data:
        curatedRoutes.value.data.find((route) => route.chain === selectedChainOrCategory.value)
          ?.assets ?? [],
    }
  else return curatedRoutes.value // return State 'loading' or 'error'
})

const fuseOptions = {
  keys: ['assetName', 'assetAddress'],
  threshold: 0, // Adjust based on the fuzziness you need
  isCaseSensitive: false,
  includeMatches: true,
  minMatchCharLength: 1,
  shouldSort: true,
  findAllMatches: true,
  ignoreLocation: false,
}

const searchedAssets = computed(() => {
  if (searchableRoutes.value.state === 'loading' || searchableRoutes.value.state === 'error')
    return searchableRoutes.value

  const searchSpace =
    selectedChainOrCategory.value === 'all'
      ? searchableRoutes.value.data.flatMap((assetSelectorData) => assetSelectorData.assets)
      : (searchableRoutes.value.data.find((route) => route.chain === selectedChainOrCategory.value)
          ?.assets ?? ([] as RouteEndPoint[]))
  const fuse = new Fuse(searchSpace, fuseOptions)
  const searchedAssets = fuse.search(searchString.value).map((result: any) => result.item)
  return { state: 'loaded', data: searchedAssets }
})

const onCloseDialog = () => {
  emits('close')
}
const onAssetSelect = ({ asset, event }: { asset: RouteEndPoint; event: Event }) => {
  event.stopPropagation()
  emits('select', { poolToUpdate: poolToUpdate.value, newSelectedAsset: asset })
  onCloseDialog()
}
</script>
