import BigNumber from 'bignumber.js'
import { useFiatPriceStore } from '~/store/fiatPriceStore'
import { getAssetId } from '~/utils/main'

export const useFiat = () => {
  const getExpectedAssetOutputUsd = ({
    assetId,
    expectedOutput,
    expectedOutputUsd,
  }: {
    assetId: string
    expectedOutput: BigNumber
    expectedOutputUsd: number | null
  }) => {
    const fiatPriceStore = useFiatPriceStore()
    const fiatPrice = fiatPriceStore.getFiatPriceAmount(assetId)
    // TODO don't show zero, show an error
    return expectedOutputUsd === null
      ? expectedOutput.multipliedBy(fiatPrice)
      : BigNumber(expectedOutputUsd)
  }
  const getExpectedOutputUsd = ({
    address,
    chain,
    // decimals,
    // expectedOutput,
    ticker,
    ...props
  }: {
    address?: string
    chain: string
    // decimals: number
    // expectedOutput: BigNumber.Value
    expectedOutput: BigNumber
    expectedOutputUsd: number | null
    ticker: string
  }) => {
    return getExpectedAssetOutputUsd({
      assetId: getAssetId(chain, ticker, address),
      // expectedOutput: intToBigNumber(expectedOutput, decimals),
      ...props,
    })
  }
  return {
    getExpectedAssetOutputUsd,
    getExpectedOutputUsd,
  }
}
